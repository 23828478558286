import StimulusReflex from 'stimulus_reflex';
import { useIntersection } from 'stimulus-use';
import ApplicationController from './application_controller';

// This controller manages the loading of a successive page of
// Available Questions as the user scrolls the Assessment Customizer UI.
export default class extends ApplicationController {
  static targets = ['endOfList'];

  initialize() {
    StimulusReflex.register(this);
  }

  // Re-initialize the controller after the morph
  // to re-register the IntersectionObserver.
  afterMorph() {
    this.initialize();
  }

  // The IntersectionObserver watches for the appearance of (10% of) the
  // endOfList element and triggers the loading of the next page.
  connect() {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useIntersection(this, { element: this.endOfListTarget, threshold: 0.1 });
  }

  appear() {
    if (this.isActionCableConnectionOpen()) {
      this.stimulate('AssessmentCustomizerFormReflex#next_page');
    }
  }
}
