import ApplicationController from './application_controller';

// This controller manages the checking and unchecking of questions in the
// Assessment Customizer UI.
export default class extends ApplicationController {
  toggle_question({ target }) {
    const checkbox = this.findCheckbox(event.target);
    const reflex = 'AssessmentCustomizerFormReflex#toggle_question';

    // Optimistically update the checkbox input if it wasn't an input field that
    // emitted the event. Partly to enhance UX but also to ensure that target
    // reflex method's expectations are met.
    if (!this.isCheckbox(target)) {
      checkbox.checked = !checkbox.checked;
    }
    this.stimulate(reflex, checkbox);
  }

  isCheckbox({ tagName, type }) {
    return tagName === 'INPUT' && type === 'checkbox';
  }

  findCheckbox(target) {
    if (this.isCheckbox(target)) {
      return target;
    } else if (target.tagName === 'LABEL') {
      return target.querySelector('input[type="checkbox"]');
    } else {
      return target.closest('label').querySelector('input[type="checkbox"]');
    }
  }
}
