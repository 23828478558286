import { Controller } from 'stimulus';

export default class extends Controller {
  sort(event) {
    const node = event.target.closest('div');
    const classes = node.classList;
    const isHeaderClick = classes.contains('field-name') || classes.contains('field-sort');
    if (!isHeaderClick) { return; }
    node.closest('th').querySelector('a').click();
  }

  displayLoader() {
    const $body = $('body');
    window.Loader.show($body);
  }

  selectOption({ target }) {
    const url = new URL(window.location.href);
    url.searchParams.delete(target.name);
    url.searchParams.append(target.name, target.value);
    this.displayLoader();
    window.location.href = url.toString();
  }

  selectLearner({ target }) {
    this.displayLoader();
    const url = new URL(window.location.href);
    url.pathname = [...url.pathname.split("/").slice(0, -1), target.value].join("/");
    window.location.href = url.toString();
  }

  navigate(event) {
    event.preventDefault();

    const { href } = event.target.closest("a");
    if (!href || href === "#") { return; }

    this.displayLoader();
    window.location.href = href;
  }
}
