// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.ts.

import { Application } from 'stimulus';
import { registerControllers } from 'stimulus-vite-helpers';
import StimulusReflex from 'stimulus_reflex';
import consumer from '../channels/consumer';
import debounced from 'debounced';

// This allows us to call application.consumer = consumer and avoid a
// deprecation warning in StimulusReflex for passing the consumer via the
// initializer.
interface StimulatedApplication<T> extends Application {
  consumer: T;
}

// Add support for debounced events in Stimulus Reflex. Defaults to 250ms
// of delay for input events to help with reduction in Typeahead processing.
debounced.initialize({
  input: { wait: 250 },
  change: { wait: 200 },
  click: { wait: 200 },
});

const application = Application.start() as StimulatedApplication<
  typeof consumer
>;
const controllers = import.meta.globEager('./**/*_controller.*s');
registerControllers(application, controllers);
StimulusReflex.initialize(application, { isolate: true });
StimulusReflex.debug = import.meta.env.DEV === true;

application.consumer = consumer;
