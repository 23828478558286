import { Controller } from 'stimulus';

export default class TrialSubscriptionsController extends Controller {
  // StimulusReflex seems to stop event propagation in the DOM when calling a
  // reflex, potentially to prevent multiple relfexes from being called. Thus,
  // when clicking on a `<div>` element, the click event does not propagate
  // down to a radio button and then marking that input element as `checked`.
  // This StimulusController address this issue. - AC 7/21
  toggleRadioButton(event: Event) {
    event.preventDefault();
    const el = event.currentTarget;

    // As `currentTarget` is of a Node type, doing an instance check allows
    // TS to assert on the type what currentTarget is.
    if (!(el instanceof HTMLElement)) {
      return;
    }

    const radioId = el.dataset.for;
    const radio: HTMLInputElement = el.parentElement.querySelector(
      `#${radioId}`
    );
    radio.checked = true;
  }
}
