import ApplicationController from './application_controller';

export default class extends ApplicationController {
  sort() {
    const element = document.getElementById('question-list');
    const baseQuestions = Array.from(
      document.getElementsByClassName('question-card')
    );

    const questions = baseQuestions.map((question: HTMLElement, index) => {
      return {
        id: question.dataset.questionId,
        position: index + 1,
      };
    });

    const options = {
      sequenceId: element.dataset.sequenceId,
      questions: questions,
    };

    this.stimulateRosh('Sequence#sort', element, options);
  }

  create(event) {
    event.preventDefault();
    const element = document.getElementById('quick-create') as HTMLInputElement;
    const options = { name: element.value };
    this.stimulateRosh('Sequence#create', element, options);
  }

  addQuestions(event) {
    event.preventDefault();
    const element = document.getElementById('quick-addQID') as HTMLInputElement;
    const options = { qids: element.value };
    this.stimulateRosh('Sequence#add_question', element, options);
  }
}
