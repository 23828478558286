import ApplicationController from './application_controller';

// This controller manages the Exit Button
export default class extends ApplicationController {
   clearFormSuccess(element) {
     element.innerHTML = '&#8987';
     window.location=element.href;

  }

  clearFormHalted(element) {
    $('#loading').hide();
    element.setAttribute('data-confirmed',true);
    element.innerHTML= 'Exit- Lose Changes';
  }
}
