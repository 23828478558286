import ApplicationController from './application_controller';

export default class extends ApplicationController {
  beforeReflex() {
    const elements = document.getElementsByClassName('fade-in');
    for (const elem of elements) {
      elem.classList.remove('fade-in');
    }
  }
}
