import { Controller } from 'stimulus';
import StimulusReflex from 'stimulus_reflex';

interface Stimulated extends Controller {
  stimulate?: (string: string, el: Element, obj: object) => void;
}

export default class extends Controller {
  connect() {
    StimulusReflex.register(this);
  }

  beforeReflex() {
    document.body.classList.add('wait');
  }

  afterReflex() {
    document.body.classList.remove('wait');
  }

  reflexHalted() {
    document.body.classList.remove('wait');
  }

  // This wraps the stimulus stimulate() method in a way that will hopefully
  // make improving the types (currently it doesn't have TS types) easier. -RC
  stimulateRosh(string: string, element: Element, options: object) {
    (this as Stimulated).stimulate(string, element, options);
  }
}
