import { Controller } from 'stimulus';

const handleError = (error) => console.log(error);

const showSpinner = (target: HTMLImageElement) => {
  target.style.display = 'inline';
};

const handleResponse = (_: Response, target: HTMLImageElement) => {
  showSpinner(target);
  location.reload();
};

export default class extends Controller {
  static targets = ['subscriberForm', 'spinner'];

  // Babel and Typescript have a bit of a conflict in a proposal around how to
  // handle class properties.
  //
  // https://github.com/hotwired/stimulus/issues/221#issuecomment-698464177
  declare readonly subscriberFormTarget: HTMLFormElement;
  declare readonly spinnerTarget: HTMLImageElement;

  updateCurrentQbank() {
    const form = this.subscriberFormTarget;
    const formData = new FormData(form) as URLSearchParams;
    const body = new URLSearchParams(formData);
    const options = {
      method: form.method,
      body: body,
    };

    fetch(form.action, options)
      .then((response) => handleResponse(response, this.spinnerTarget))
      .catch(handleError);
  }
}
