import Sortable from 'sortablejs';

import ApplicationController from './application_controller';

// This controller manages the drag-and-drop reordering of selected questions in
// the AssessmentCustomizer UI.
export default class extends ApplicationController {
  connect() {
    super.connect();
    Sortable.create(this.element, {
      handle: '.sortable-handle',
      draggable: '.sortable-item',
      ghostClass: 'sortable-bg-blue',
      onStart: this.setOriginalOrdering.bind(this),
      onEnd: this.triggerReordering.bind(this),
    });
  }

  // Because the Selected Questions UI allows reordering even when filtered,
  // we can't just use the oldIndex and newIndex provided by the Sortable
  // event `onEnd` to derive the origin and target position values. In an
  // unfiltered list, origin and target position are just oldIndex+1 and
  // newIndex+1, respectively, but in a filtered list, this isn't the case.
  //
  // In general, the origin and target positions are the values displayed in
  // the number input fields of each element, so we record the position values
  // at the start of a movement and use those to determine the target position
  // in the `onEnd` handler.
  setOriginalOrdering({ from }) {
    this.originalOrdering = Array.from(
      from.querySelectorAll('input[type="number"]')
    ).map((e) => e.value);
  }

  // Sortable Event docs:
  // https://github.com/SortableJS/Sortable#event-object-demo
  // The origin position is recorded in the dataset of the dragged element.
  triggerReordering({ item, newIndex }) {
    const draggedInput = item.querySelector('input[type="number"]');
    const reflex = draggedInput.dataset.reflex.split('->')[1];
    const targetPosition = this.originalOrdering[newIndex];
    draggedInput.value = targetPosition;
    this.stimulate(reflex, draggedInput);
  }
}
