import ApplicationController from './application_controller';

const monthOnlyRegex = /^\d{2}$/;

const monthAndDayRegex = /^\d{2}\/\d{2}$/;

const missingDaySeparatorRegex = /^\d{3}$/;

const missingYearSeparatorRegex = /^\d{2}\/\d{3}$/;

const handleBackspace = (element, value) => {
  if (value.endsWith('/')) {
    element.value = value.slice(0, -1);
  }
};

const handleInput = (element, value) => {
  if (monthOnlyRegex.test(value) || monthAndDayRegex.test(value)) {
    element.value = value + '/';
  }

  if (missingDaySeparatorRegex.test(value)) {
    element.value = value.slice(0, 2) + '/' + value.slice(-1);
  }

  if (missingYearSeparatorRegex.test(value)) {
    element.value = value.slice(0, 5) + '/' + value.slice(-1);
  }
};

class DateInputController extends ApplicationController {
  slashifyDate(event) {
    const element = event.target;
    const value = event.target.value;

    if (event.inputType === 'deleteContentBackward') {
      handleBackspace(element, value);
    } else {
      handleInput(element, value);
    }
  }
}

export default DateInputController;
